import React from "react";
// import { graphql } from "gatsby";
import Root from "../Root";
import SEO from "../seo";
import Pools from "./Pools";

// export const query = graphql`
//   query($limit: Int!, $skip: Int!) {
//     allAllPoolsJson(limit: $limit, skip: $skip) {
//       nodes {
//         name
//         numberOfSubPools
//         slug
//         isVerified
//       }
//     }
//   }
// `;

const PoolsPage = props => {
  // const pools = props.data.allAllPoolsJson.nodes;

  const { pools, currentPage, numberOfPages } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numberOfPages;
  const prevPage = currentPage - 1 === 1 ? 0 : currentPage - 1;
  const nextPage = currentPage + 1;

  return (
    <Root>
      <SEO
        title="List Of All Cryptocurrency Mining Pools"
        description="View a list of all cryptocurrency mining pools and check what coins you can mine using their services."
      />
      <Pools
        pools={pools}
        isFirst={isFirst}
        isLast={isLast}
        prevPage={prevPage}
        nextPage={nextPage}
        currentPage={currentPage}
        numberOfPages={numberOfPages}
      />
    </Root>
  );
};

export default PoolsPage;
