import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "gatsby-theme-material-ui";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("sm")]: {
      width: "unset",
      justifyContent: "space-around",
    },
  },
  span: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "unset",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: 10,
    },
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    transition: "color .1s ease-out",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  button: {
    fontWeight: "500",
    fontSize: "0.9rem",
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.border.gradient.main}`,
    textTransform: "none",
  },
}));

const PageControls = props => {
  const classes = useStyles(props);
  const { prevPage, nextPage, allPage, isFirst, isLast } = props;

  return (
    <div className={classes.root}>
      <span className={classes.span}>
        {isFirst ? (
          <div>
            <Button style={{ opacity: 0 }} disabled size="small" variant="outlined" color="primary" disableElevation>
              ← First
            </Button>
          </div>
        ) : (
          <Link className={classes.link} to={prevPage}>
            <Button className={classes.button} size="small" variant="outlined" color="primary" disableElevation>
              ← Previous
            </Button>
          </Link>
        )}
      </span>
      <span className={classes.span}>
        {!isLast && (
          <Link className={classes.link} to={nextPage}>
            <Button className={classes.button} size="small" variant="outlined" color="primary" disableElevation>
              Next →
            </Button>
          </Link>
        )}
      </span>
      <span className={classes.span}>
        <Link className={classes.link} to={allPage}>
          <Button className={classes.button} size="small" variant="outlined" color="primary" disableElevation>
            View All
          </Button>
        </Link>
      </span>
    </div>
  );
};

export default PageControls;
