import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
      marginBottom: "20px",
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "12px",
      marginBottom: "12px",
      flexDirection: "inherit",
    },
  },
  navajo: {
    margin: "40px auto",
    "& div:first-of-type": {
      margin: "0 auto",
    },
  },
  mobile: {
    width: "300px",
    margin: "0 auto",
    display: "block",
  },
}));

const NavajoZillaBottom = props => {
  const classes = useStyles(props);

  useEffect(() => {
    const coinzillaScript = document.createElement("script");
    coinzillaScript.src = "https://coinzillatag.com/lib/display.js";
    coinzillaScript.async = true;
    document.body.appendChild(coinzillaScript);

    // Mobile
    const coinzillaMobile = window.coinzilla_display || [];
    const mobile_preferences = {};
    mobile_preferences.zone = "30105b8033a0a98a7";
    mobile_preferences.width = "300";
    mobile_preferences.height = "250";
    coinzillaMobile.push(mobile_preferences);

    return () => {
      document.body.removeChild(coinzillaScript);
    };
  }, []);

  return (
    <Container maxWidth="lg">
      <div className={clsx(classes.navajo, classes.mobile)}>
        <div className="coinzilla" data-zone="C-30105b8033a0a98a7"></div>
      </div>
    </Container>
  );
};

export default NavajoZillaBottom;
